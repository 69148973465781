<script lang="ts" setup>
const colorMode = useColorMode();

const isDark = computed({
  get: () => colorMode.value === 'dark',
  set () {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
});
</script>

<template>
  <UToggle
    off-icon="i-heroicons-sun-20-solid"
    on-icon="i-heroicons-moon"
    v-model="isDark" />
</template>

<style>
</style>